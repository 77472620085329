import React, { useEffect, useReducer } from 'react'
import Layout from '../../components/Layout'
import queryString from 'query-string'
import LayoutSelector from '../../components/layouts/layoutSelector/LayoutSelector'
import MainFormContainer from '../../components/layouts/mainFormContainer/MainFormContainer'
import FunnelSideBar from '../../components/funnelSideBar/FunnelSideBar'
import styles from './index.module.scss'
import * as api from '../../api/user'
import SpinnerWithText from '../../components/spinnerWithText/SpinnerWithText'
import Done from '../../components/Done/Done'
import { FormattedMessage } from 'react-intl'
import { graphql } from 'gatsby'
import { SeoData } from '../../types/Seo'
import dataLayer from '../../helpers/functions/dataLayer'

function reducer(state, action) {
  switch (action.type) {
    case 'CHECK_TOKEN':
      return {
        ...state,
        loading: true,
      }
    case 'CHECK_TOKEN_OK':
      return {
        ...state,
        loading: false,
        success: true,
        token: action.payload.token,
      }
    case 'CHECK_TOKEN_FAILED':
      return {
        ...state,
        tokenError: action.payload.error,
        loading: false,
        token: null,
      }
    case 'NO_TOKEN':
      return {
        ...state,
        success: true,
        token: null,
      }
    default:
      throw new Error('Unknown action')
  }
}

interface Props {
  data: {
    seoData: SeoData
  }
}

export default function index({ data }: Props) {
  const [localState, localDispatch] = useReducer(reducer, {
    tokenError: null,
    error: null,
    loading: false,
    success: false,
    token: null,
  })

  useEffect(() => {
    checkToken()
  }, [])

  const checkToken = () => {
    const params = queryString.parse(window.location.search)
    if (!params.token) {
      localDispatch({
        type: 'NO_TOKEN',
      })
      return
    }
    localDispatch({
      type: 'CHECK_TOKEN',
    })
    api
      .checkNewsLetterToken(params.token)
      .then(res => {
        if (res.success && res.updated) {
          dataLayer.push({
            event: 'newsletter',
            action: 'conversion',
          })
        }
        localDispatch({
          type: 'CHECK_TOKEN_OK',
          payload: {
            token: params.token,
          },
        })
      })
      .catch(err => {
        localDispatch({
          type: 'CHECK_TOKEN_FAILED',
          payload: {
            error: err.message,
          },
        })
      })
  }

  return (
    <Layout seoData={data.seoData} selector={true}>
      <LayoutSelector hideSelectorSteps={true} title="Newsletter">
        <MainFormContainer>
          <div className={styles.services_content}>
            {localState.loading ? <SpinnerWithText text="cargando..." /> : null}
            {!localState.loading && localState.success && !localState.token ? (
              <p className={styles.gracias}>
                <Done />
                <FormattedMessage id="newsletter.no_token" />
              </p>
            ) : null}
            {!localState.loading && localState.success && localState.token ? (
              <p className={styles.gracias}>
                <Done />
                <FormattedMessage id="newsletter.gracias" />
              </p>
            ) : null}
            {!localState.loading && localState.tokenError ? (
              <p className={styles.gracias}>
                <FormattedMessage id="newsletter.error" />
              </p>
            ) : null}
          </div>
        </MainFormContainer>
        <FunnelSideBar />
      </LayoutSelector>
    </Layout>
  )
}

export const pageQuery = graphql`
  query newsletterPageQuery($url: String!) {
    seoData: pageInfo(url: { eq: $url }) {
      ...seoinformation
    }
  }
`
